<template>
  <v-container class="onboarding-step">
    <div v-if="title" class="text-h2 my-10 font-weight-thin">
      {{ title }}
    </div>
    <div class="onboarding-step__content">
      <slot></slot>
    </div>
    <div class="onboarding-step__actions elevation-4">
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row>
          <RaiSupport text />
          <v-spacer />
          <v-fade-transition>
            <VxBtn v-if="showSkip" class="mr-2" text @click="skip">
              {{ skipText }}
            </VxBtn>
          </v-fade-transition>
          <v-fade-transition>
            <VxBtn v-if="showNext" depressed @click="next">
              {{ nextText }}
            </VxBtn>
          </v-fade-transition>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { VxBtn } from "@/core-ui";
import RaiSupport from "@/components/support/RaiSupport.vue";

export default {
  name: "OnboardingStep",
  components: { VxBtn, RaiSupport },
  props: {
    title: {
      type: String,
      default: "",
    },
    nextText: {
      type: String,
      default: "Next",
    },
    nextTo: {
      type: String,
      default: "",
    },
    showNext: {
      type: Boolean,
      default: true,
    },
    skipText: {
      type: String,
      default: "Skip",
    },
    skipTo: {
      type: String,
      default: "",
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    to() {
      return this.nextTo ? { name: this.nextTo } : undefined;
    },
    skipLink() {
      return this.skipTo ? { name: this.skipTo } : undefined;
    },
  },
  methods: {
    next(e) {
      this.$emit("next", e);
      if (e.defaultPrevented) return;
      this.$router.push(this.to);
    },
    skip(e) {
      this.$emit("skip", e);
      if (e.defaultPrevented) return;
      this.$router.push(this.skipLink);
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-step {
  height: 100%;
  padding-bottom: 80px;
}

.onboarding-step__actions {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.theme--light .onboarding-step__actions {
  background-color: #f7f9f9;
}

.theme--dark .onboarding-step__actions {
  background-color: #0a0d0f;
}
</style>
