<script>
import { SET_RESTORE_ID } from "@/graphql/store/mutations.gql";
import { mapGetters, mapMutations } from "vuex";
import { roles } from "@/constants";
export default {
  name: "Freshdesk",
  data: () => ({
    loaded: false,
    visible: false,
    version: process.env.VUE_APP_VERSION,
  }),
  inject: {
    currentStore: {
      default() {
        return () => [];
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),
    restoreId() {
      return this.$store.state.auth.freshdeskRestoreId;
    },
    externalId() {
      return this.$store.state.auth.freshdeskExternalId;
    },
    supportEmail() {
      return this.$store.state.auth.freshdeskEmail;
    },
    store() {
      return this.currentStore();
    },
    userRoleDisplay() {
      return roles[this.userRole].text;
    },
  },
  watch: {
    restoreId() {
      this.initializeFreshdesk();
    },
  },
  mounted() {
    this.initializeFreshdesk();

    window.fcWidget.on("widget:closed", () => {
      this.visible = false;
    });
  },
  methods: {
    ...mapMutations("auth", ["setFreshdeskRestoreId"]),
    async setRestoreId(restoreId) {
      await this.$apollo.mutate({
        mutation: SET_RESTORE_ID,
        context: { skipEmployeeValidation: true },
        variables: {
          input: {
            freshdeskRestoreId: restoreId,
          },
        },
      });

      // set restore id in vuex, this may not be needed. when we get the restore id from freshdesk we just save in db.
      // restore id is only useful on page reload/new page access so saving it in vuex does nothing i believe.
      // this.setFreshdeskRestoreId(restoreId);
    },
    toggle() {
      if (this.visible) {
        window.fcWidget.close();
      } else {
        window.fcWidget.open();
        this.visible = true;
      }
    },
    async initializeFreshdesk() {
      // set the externalId and restoreId
      window.fcWidgetMessengerConfig = {
        externalId: this.externalId,
        restoreId: this.restoreId || null,
      };

      // initialize freshdesk
      window.fcWidget.init({
        token: "67688cb4-e95c-4e7e-8402-729f2b5258f7",
        host: "https://resaleai.freshchat.com",
        externalId: this.externalId,
        restoreId: this.restoreId || null,
        config: {
          headerProperty: {
            hideChatButton: true,
          },
        },
      });

      // identify the user
      this.freshdeskIdentify();
    },
    async freshdeskIdentify() {
      // get the user from freshdesk
      window.fcWidget.user.get((resp) => {
        var status = resp && resp.status,
          data = resp && resp.data;

        // if we cant get it, set user properties and handle getting restore id
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            email: this.supportEmail,
            cf_access: this.userRoleDisplay,
            cf_active_store: this.store.storeNumber.toString(),
            cf_rai_version: this.version,
            cf_pos_version: this.$raiPos ? this.$raiPos.raiPosVersion() : null,
          });
          window.fcWidget.on("user:created", (resp) => {
            var status = resp && resp.status,
              data = resp && resp.data;

            // on successful user creation, take restore id and set it in db and vuex
            if (status === 200) {
              if (data.restoreId) {
                this.setRestoreId(data.restoreId);
              }
            }
          });
        } else {
          window.fcWidget.user.setProperties({
            cf_access: this.userRoleDisplay,
            cf_active_store: this.store.storeNumber.toString(),
            cf_rai_version: this.version,
            cf_pos_version: this.$raiPos ? this.$raiPos.raiPosVersion() : null,
          });
        }
      });
    },
  },
  render() {
    return this.$scopedSlots.default({
      toggle: this.toggle,
      visible: this.visible,
      loaded: this.loaded,
      widget: this.webWidgetEl,
    });
  },
};
</script>
