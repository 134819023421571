import { defineComponent } from "vue";

export default defineComponent({
  name: "I18nArray",
  inheritAttrs: false,
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    values: [],
  }),
  created() {
    const values = this.$t(this.path);

    if (!Array.isArray(values)) {
      throw new Error("Provided i18n value is not an array");
    }

    this.values = values;
  },
  render() {
    const slots = Object.keys(this.$scopedSlots).map((slot) =>
      this.$createElement("template", { slot }, this.$scopedSlots[slot]?.call())
    );

    const i18nElements = this.values.map((_, idx) => {
      const pathValue = `${this.path}.${idx}`;
      return this.$createElement(
        "i18n",
        {
          key: pathValue,
          props: { ...this.$attrs, path: pathValue },
        },
        slots
      );
    });

    return this.$createElement("div", i18nElements);
  },
});
