import { defineComponent } from "vue";
import OnboardingStep from "./components/OnboardingStep.vue";
import i18n from "@/plugins/vue-i18n";

const BaseStepView = defineComponent({
  name: "BaseStepView",
  components: { OnboardingStep },
  props: {
    nextTo: {
      type: String,
      default: "",
    },
    storeId: {
      type: [String, Number],
      required: true,
    },
    showNext: {
      type: Boolean,
      default: true,
    },
    nextText: {
      type: String,
      default: i18n.t("onboarding.default.nextText"),
    },
    skipText: {
      type: String,
      default: i18n.t("onboarding.default.skipText"),
    },
    skipTo: {
      type: String,
      default: "",
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
  },
});

export { BaseStepView };
export default BaseStepView;
