<template>
  <Freshdesk v-slot="context">
    <slot v-bind="context">
      <v-btn :fab="fab" v-bind="$attrs" color="primary" @click="context.toggle">
        <template v-if="context.visible">
          <v-icon left>$cancel</v-icon>
          <span v-if="!fab">Cancel</span>
        </template>
        <template v-else>
          <v-icon left>$help</v-icon>
          <span v-if="!fab">Help</span>
        </template>
      </v-btn>
    </slot>
  </Freshdesk>
</template>

<script>
import Freshdesk from "./Freshdesk.vue";

export default {
  name: "RaiSupoort",
  components: { Freshdesk },
  inheritAttrs: false,
  props: {
    fab: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
